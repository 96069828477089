.wrapper {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	
	&Inner {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 0 24px;
		position: relative;
		z-index: 2;
	}
	
	&--open {
		.dropdown {
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
		}
	}
}

.content {
	display: block;
}

.name {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: var(--content-primary);
	text-align: right;
	
	+ .company {
		margin-top: 4px;
	}
}

.company {
	font-weight: bold;
	font-size: 14px;
	line-height: 17px;
	text-align: right;
	color: var(--content-secondary);
	max-width: 150px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-left: auto;
	margin-right: 0;
}

.dropdown {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	min-width: 240px;
	background-color: var(--background-primary);
	box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
	border-radius: 0 0 16px 16px;
	padding-top: 90px;
	opacity: 0;
	visibility: hidden;
	transition: all .1s ease-in-out;
	pointer-events: none;
	z-index: 1;
}