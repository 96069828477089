.wrapper {
	border-radius: 4px;
	width: 24px;
	min-width: 24px;
	height: 24px;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 24px;
		height: 24px;
		background-color: var(--brand-secondary);
		opacity: 0;
		visibility: hidden;
		transition: all .1s ease-in-out;
		z-index: 1;
	}
	
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 20px;
		height: 20px;
		border: 2px solid #c0c1c5;
		z-index: 0;
		border-radius: 4px;
	}
	
	&Active {
		&:before {
			opacity: 1;
			visibility: visible;
		}
		
		.icon {
			opacity: 1;
			visibility: visible;
		}
	}
}

.icon {
	width: 24px;
	height: 24px;
	opacity: 0;
	visibility: hidden;
	transition: all .1s ease-in-out;
	color: var(--content-primary-alt);
	position: relative;
	z-index: 1;
	
	svg {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.input {
	display: none;
	visibility: hidden;
	opacity: 0;
}