.wrapper {
	padding: 56px 24px 24px;
	position: relative;
	overflow: hidden;
	
	@media (max-width: 576px) {
		padding: 14px 0 0;
	}
}

.spinner {
	margin: 100px 0;
}

.headerMobileTitle {
	font-weight: 800;
	font-size: 20px;
	line-height: 140%;
	margin-bottom: 26px;
	display: none;
	color: var(--content-primary-alt);
	text-align: center;
	margin-top: 10px;
	
	@media (max-width: 576px) {
		display: block;
	}
}

.header {
	display: flex;
	align-items: center;
	
	&Image {
		width: 256px;
		min-width: 256px;
		height: 256px;
		pointer-events: none;
		
		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: contain;
		}
		
		@media (max-width: 992px) {
			position: absolute;
			opacity: 0.1;
			right: -50px;
		}
	}
	
	&Content {
		width: 100%;
		margin-left: 40px;
		
		@media (max-width: 992px) {
			margin-left: 0;
		}
	}
	
	@media (max-width: 576px) {
		padding: 0 16px;
	}
}

.tab {
	
	&Wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 24px;
		user-select: none;
		
		&Inner {
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--content-thirdary-alt);
			border-radius: 40px;
			padding: 2px;
		}
	}
	
	&Item {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		border-radius: 40px;
		padding: 10px 24px;
		text-align: center;
		cursor: pointer;
		transition: all .2s ease-in-out;
		white-space: nowrap;
		width: calc(100% + 20px);
		color: var(--content-primary-alt);
		
		@media (max-width: 576px){
			padding: 10px;
			font-size: 12px;
		}
		
		&Active {
			background: var(--background-primary);
			box-shadow: 0 2px 15px rgba(9, 17, 38, 0.1);
			color: var(--content-primary);
		}
	}
}

.category {
	font-size: 14px;
	line-height: 17px;
	color: var(--content-secondary-alt);
	margin-bottom: 14px;
	
	@media (max-width: 992px) {
		font-size: 13px;
		line-height: 16px;
		margin-bottom: 8px;
	}
}

.title {
	font-weight: 500;
	font-size: 18px;
	line-height: 150%;
	color: var(--content-primary-alt);
	margin-bottom: 14px;
	
	@media (max-width: 992px) {
		font-size: 16px;
	}
}

.tags {
	margin-bottom: 14px;
	display: flex;
	row-gap: 10px;
	flex-wrap: wrap;
}

.info {
	display: flex;
	width: calc(100% + 48px);
	height: 56px;
	overflow: hidden;
	margin: 0 -24px 24px;
	
	&Inner {
		display: flex;
		align-items: flex-start;
		overflow: auto;
		white-space: nowrap;
		scrollbar-width: none;
		-ms-overflow-style: none;
		padding: 0 16px;
		height: 72px;
		
		> * {
			height: 56px;
		}
		
		&:after {
			content: '';
			width: 8px;
			min-width: 8px;
			height: 8px;
			display: block;
		}
		
		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}
}

.content {
	background: var(--background-primary);
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
	border-radius: 16px;
	padding: 20px;
	
	@media (max-width: 576px) {
		padding: 0;
	}
}

.image {
	width: 100%;
	height: 100%;
	display: block;
	border: 1px solid #ededed;
	border-radius: 8px;
	overflow: hidden;
	
	img {
		width: 100%;
		height: 100%;
		display: block;
	}
	
}

.button {
	&List {
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;
	}
	
	&Block {
		display: block;
	}
	
	@media (max-width: 576px) {
		&List {
			padding: 12px;
			margin-bottom: 0;
		}
	}
}

.listTitle {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: var(--content-primary);
	margin-bottom: 20px;
	
	@media (max-width: 576px) {
		display: none;
	}
}

.history {
	border: solid var(--background-line);
	border-width: 1px 0;
	
	&Header {
		display: flex;
		width: 100%;
		border-bottom: 1px solid var(--background-line);
		
		.historyCell {
			padding: 12px 8px;
			font-weight: 500;
			font-size: 13px;
			line-height: 16px;
			color: var(--content-secondary);
		}
	}
	
	&Cell {
		flex: 3;
		width: 150px;
		font-size: 14px;
		line-height: 140%;
		color: var(--content-primary);
		cursor: pointer;
		
		&:nth-child(1){
			flex: 2;
		}
	}
	
	&Row {
		display: flex;
		align-items: center;
	}
	
	&Body {
		display: block;
		width: 100%;
		
		.historyCell {
			padding: 12px 8px;
		}
	}
}

.document {
	display: block;
	
	&Error {
		padding: 24px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		border: 1px solid #ededed;
		border-radius: 8px;
		
		&Title {
			font-size: 16px;
			margin: 0 auto 24px;
			max-width: 400px;
			text-align: center;
		}
	}
	
	@media (max-width: 576px) {
		padding: 12px;
	}
}

.list {
	@media (max-width: 576px) {
		padding: 16px;
	}
}


.branches {
	display: block;
	padding: 1rem;
  
	&Title {
	  font-weight: bold;
	  font-size: 18px;
	  line-height: 140%;
	  color: white;
	  margin: 0 auto 24px;
	  text-align: center;
	  max-width: 760px;
	}
  
	&Action {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  margin-top: 1rem;
	}
  }
  
  .workers {
	background: rgb(240, 240, 240);
	display: block;
	margin: 20px;
	margin-top: 5rem;
	padding: 20px;
	border-radius: 8px;
  }
  
  .empty{
	text-align: center;
  }