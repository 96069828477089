.wrapper {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	margin: 16px;
	z-index: 9999;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.item {
	background: rgba(32, 32, 34, 0.9);
	box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	padding: 16px;
	color: var(--content-primary-alt);
	max-width: 350px;
	pointer-events: auto;
	will-change: transform;
	display: flex;
	align-items: center;
	
	&Wrapper {
		position: absolute;
		top: 0;
		right: 0;
		transition: all .3s ease-in-out;
		will-change: transform;
	}
}

.icon {
	width: 20px;
	min-width: 20px;
	height: 20px;
	display: block;
	margin-right: 12px;
	
	&--success {
		color: var(--status-green);
	}
	
	&--error {
		color: var(--status-red);
	}
	
	&--blank {
		color: var(--brand-secondary);
	}
	
	&--loading {
		color: var(--status-orange);
	}
	
	svg {
		width: 100%;
		height: 100%;
		display: block;
	}
}