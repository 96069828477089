.wrapper {
	position: relative;
	margin-bottom: 24px;
	width: 100%;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
}

.label {
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	color: var(--content-primary);
	cursor: pointer;
	z-index: 1;
	user-select: none;
	margin-bottom: 8px;

	span {
		color: var(--status-red);
		margin-right: 8px;
	}
}

.map {
	border: 1px solid var(--content-thirdary);
	border-radius: 8px;
	width: 100%;
	min-height: 400px;
	overflow: hidden;
}

.errorLabel {
	position: absolute;
	left: 0;
	top: 100%;
	padding-top: 5px;
	display: block;
	font-weight: bold;
	font-size: 12px;
	line-height: 140%;
	color: var(--status-red);
}