.empty {

    &Wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }


    &Image {
        width: 100%;
        max-width: 580px;
        margin: 0 auto 16px;
        pointer-events: none;

        img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    &Title {
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        color: var(--content-primary);
        margin-bottom: 24px;
    }

    &Description {
        font-weight: normal;
        font-size: 16px;
        line-height: 170%;
        color: var(--content-secondary);
        margin: 0 auto 24px;
        width: 100%;
        max-width: 500px;
    }
}