.wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
	position: relative;
	background: var(--background-secondary);
	border-radius: 8px;
	padding: 0 16px;
	min-height: 56px;
	cursor: pointer;
	
	&Disabled {
		cursor: not-allowed;
		
		.label {
			cursor: not-allowed;
			pointer-events: none;
			color: var(--content-thirdary);
			
			span {
				color: var(--content-thirdary);
			}
		}
		
		.switcher  {
			pointer-events: none;
			opacity: .6;
		}
	}
}

.label {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: var(--content-primary);
	width: 100%;
	
	span {
		color: var(--status-red);
		margin-right: 8px;
	}
}

.input {
	display: none;
	visibility: hidden;
	opacity: 0;
	
	&:checked + .switcher {
		background: var(--status-green);
		border-color: var(--status-green);
		
		.switcherDot {
			background-color: var(--background-primary);
			transform: translateX(16px);
		}
	}
}

.switcher {
	position: relative;
	width: 40px;
	height: 24px;
	border-radius: 20px;
	border: 1px solid var(--content-thirdary);
	transition: all .2s ease-in-out;
	
	&Dot {
		position: absolute;
		top: 0;
		left: 1px;
		bottom: 0;
		margin: auto;
		width: 20px;
		height: 20px;
		background-color: var(--content-thirdary);
		border-radius: 50%;
		transition: all .2s ease-in-out;
	}
}

.errorLabel {
	position: absolute;
	left: 0;
	top: 100%;
	padding-top: 5px;
	display: block;
	font-weight: bold;
	font-size: 12px;
	line-height: 140%;
	color: var(--status-red);
}