.wrapper {
	position: relative;
	display: block;
	margin-left: 24px;
}

.button {
	width: 40px;
	min-width: 40px;
	height: 40px;
	background-color: var(--background-secondary);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
	user-select: none;
	transition: all .3s ease-in-out;
	
	&Icon {
		width: 20px;
		height: 20px;
		color: var(--content-primary);
		transition: all .3s ease-in-out;
		
		svg {
			width: 100%;
			height: 100%;
			display: block;
		}
		
		.buttonEmpty & {
			color: var(--content-secondary);
			
			svg {
				path {
					fill: var(--content-secondary);
				}
			}
		}
	}
	
	&Badge {
		position: absolute;
		top: -4px;
		right: -4px;
		background-color: var(--status-red);
		border-radius: 50%;
		width: 20px;
		min-width: 20px;
		height: 20px;
		font-weight: bold;
		font-size: 12px;
		line-height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--content-primary-alt);
	}
	
	&Visible {
		background-color: var(--brand-secondary);
		
		.buttonIcon {
			color: var(--content-primary-alt);
			
			svg {
				path {
					fill: var(--content-primary-alt);
				}
			}
		}
	}
}

.dropdown {
	background: var(--background-secondary);
	border: 1px solid var(--background-line);
	box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
	border-radius: 16px;
	position: absolute;
	top: calc(100% + 10px);
	left: 50%;
	width: 375px;
	min-height: 325px;
	max-height: 625px;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	transform: translateX(-50%) translateY(-20px);
	transition: all .2s ease-in-out;
	will-change: transform;
	display: flex;
}