.wrapper {
	display: block;
	position: relative;
	cursor: pointer;
	width: 100%;
	height: 260px;
}

.header {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.title {
	font-size: 14px;
	line-height: 180%;
	color: var(--content-secondary);
}

.date {
	font-weight: bold;
	font-size: 14px;
	line-height: 180%;
	color: var(--content-primary);
}

.columns {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	height: 260px;
	position: relative;
}

.item {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 260px;
	text-align: center;

	&Bar {
		width: 8px;
		min-width: 8px;
		background: #ebf1fb;
		border-radius: 14px;
		position: relative;
		z-index: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		&Wrapper {
			position: relative;
			width: 18px;
			min-width: 18px;
			height: 230px;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: hidden;
			z-index: 0;
			margin: 0 auto 16px;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				background: linear-gradient(180deg, rgba(235, 241, 251, 0) 0%, #ebf1fb 100%);
				border-radius: 14px;
				width: 1px;
				height: 100%;
			}
		}

		&All,
		&Processing,
		&Rejected,
		&Approved {
			transition: all .2s ease-in-out;
			width: 8px;
			height: 0;
		}
	}

	&Date {
		font-size: 12px;
		line-height: 15px;
		color: #b6c1d3;
		transition: all .2s ease-in-out;
		text-align: center;
	}

	&:hover {
		.item {
			&Bar {

				&All {
					background-color: var(--brand-secondary);
				}

				&Processing {
					background-color: var(--status-orange);
				}

				&Rejected {
					background-color: var(--status-red);
				}

				&Approved {
					background-color: var(--status-green);
				}
			}

			&Date {
				font-weight: bold;
				color: var(--content-primary);
			}
		}
	}
}

.tooltip {
	position: relative;
	background: #fff;
	box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
	display: block;
	margin-bottom: 60px;
	z-index: 1;
	width: 282px;
	padding: 16px;
	opacity: 0;
	visibility: hidden;
	transition: all .1s ease-in-out;

	&Title {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: var(--content-primary);
		text-align: left;
		margin-bottom: 16px;
	}

	&Stats {
		padding: 12px;
		display: flex;
		justify-content: space-between;
		background-color: var(--status-info);
		border-radius: 8px;

		&Title {
			font-weight: 500;
			font-size: 13px;
			line-height: 16px;
			color: var(--content-primary);
		}

		&Value {
			font-weight: 900;
			font-size: 13px;
			line-height: 16px;
		}

		&:not(:last-child) {
			margin-bottom: 8px;
		}

		&All {
			background-color: var(--status-info);

			.tooltipStatsValue {
				color: var(--brand-secondary);
			}
		}

		&Processing {
			background-color: var(--status-orange-light);

			.tooltipStatsValue {
				color: var(--status-orange);
			}
		}

		&Rejected {
			background-color: var(--status-red-light);

			.tooltipStatsValue {
				color: var(--status-red);
			}
		}

		&Approved {
			background-color: var(--status-green-light);

			.tooltipStatsValue {
				color: var(--status-green);
			}
		}
	}

	&-enter {
		opacity: 0;
	}

	&-enter-active {
		opacity: 1;
		transition: opacity .2s;
	}

	&-exit {
		opacity: 1;
	}

	&-exit-active {
		opacity: 0;
		transition: opacity .2s;
	}
}