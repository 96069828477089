.list {
	display: flex;
	align-items: center;
}

.item {
	display: flex;
	align-items: center;
	
	&Icon {
		width: 20px;
		min-width: 20px;
		height: 20px;
		margin-right: 8px;
		color: var(--brand-secondary);
		
		svg {
			display: block;
			width: 100%;
			height: 100%;
			
			path {
				fill: var(--brand-secondary);
			}
		}
	}
	
	&Value {
		font-weight: 500;
		font-size: 12px;
		line-height: 140%;
		color: var(--content-primary);
		display: block;
		cursor: pointer;


		@media (max-width: 576px) {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
		  }
	}
	
	&Separator {
		width: 18px;
		min-width: 18px;
		height: 18px;
		margin: 0 8px;
		color: var(--content-thirdary);
		
		svg {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	
	&:last-child {
		.itemValue {
			color: var(--content-thirdary);
			cursor: default;
		}
		
		.itemSeparator {
			display: none;
		}
	}
	
	&:first-child {
		.itemValue {
			color: var(--content-primary);
		}
	}
	
	.list--dark & {
		.itemValue {
			color: var(--content-primary-alt);
		}
		
		.itemSeparator {
			color: var(--content-thirdary-alt);
		}
		
		&:last-child {
			.itemValue {
				color: var(--content-secondary-alt);
			}
		}
		
		&:first-child {
			.itemValue {
				color: var(--content-primary-alt);
			}
		}
	}
}