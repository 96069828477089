.wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 26px;
    
    &Inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 24px 32px 24px;
    }
}

.title {
	color: var(--brand-secondary);
	font-size: 20px;
	line-height: 140%;
	font-weight: 800;
	margin-bottom: 16px;
	text-align: center;
}

.fields {
	width: 100%;
	margin-bottom: 32px;
}

.button {
	width: 100%;
	max-width: 200px;
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba(255,255,255,.7);
    user-select: none;
}