.wrapper {
	display: block;
}

.table {
	width: 100%;
	height: 100%;
	border-spacing: 0 16px;
}

.header {
	width: 100%;

	&Row {
		width: 100%;
	}

	&Cell {
		font-weight: 600;
		font-size: 12px;
		line-height: 150%;
		color: var(--content-secondary);
		text-align: left;
		padding: 3px 16px;

		&:first-child {
			padding-left: 0;
		}
	}
}

.body {
	width: 100%;
}

.row {
	background: var(--background-primary);
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.05);
	border-radius: 16px;
	cursor: pointer;
	user-select: none;
	transition: all .3s ease-in-out;
	padding: 0;

	&:hover {
		transform: translateY(-3px);
	}
}

.cell {
	position: relative;
	font-weight: 500;
	font-size: 13px;
	line-height: 140%;
	color: var(--content-primary);
	padding: 16px;

	&:not(:last-child) {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 1px;
			height: 48px;
			border-right: 1px dashed var(--background-line);
		}
	}

	&:first-child {
		border-top-left-radius: 16px;
		border-bottom-left-radius: 16px;
	}

	&:last-child {
		border-top-right-radius: 16px;
		border-bottom-right-radius: 16px;
	}
}


.action {
	background: var(--background-primary);
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;

	svg {
		width: 20px;
		height: 20px;
		display: block;
	}

	&Menu {
		&List {
			background-color: var(--background-primary);
			box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
			border-radius: 12px;
			padding: 0 16px;
			min-width: 200px;
			max-width: 280px;
			display: block;
			position: relative;
			cursor: pointer;
			opacity: 0;
			visibility: hidden;
			transition: .2s ease-in-out;
			z-index: 1;
		}

		&Item {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			min-height: 54px;
			padding: 8px;
			cursor: pointer;
			user-select: none;
			text-align: right;

			&Title {
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				color: var(--content-primary);
			}

			&Icon {
				width: 20px;
				min-width: 20px;
				height: 20px;
				margin-left: 12px;
				color: var(--content-primary);
			}

			&:not(:last-child) {
				border-bottom: 1px solid var(--background-line);
			}
		}
	}
}

.titleCell {
	padding: 0;
	width: 400px;
	height: 100%;
	overflow: hidden;

	&Inner {
		display: flex;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-top-left-radius: 16px;
		border-bottom-left-radius: 16px;
	}

	&Image {
		width: 68px;
		min-width: 68px;
		background: linear-gradient(225deg, var(--gradient-start) 0%, var(--gradient-end) 100%);

		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: contain;
			object-position: center;
		}
	}

	&Content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 16px;
		width: 100%;
	}

	&Category {
		font-weight: 600;
		font-size: 12px;
		line-height: 150%;
		color: var(--brand-secondary);
		margin-bottom: 6px;
	}

	&Value {
		display: block;
	}
}

.actionCell {
	width: fit-content;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
}

.stepCell {
	&Title {
		margin-bottom: 6px;
	}

	&Value {
		display: block;
	}
}