.wrapper {
  display: block;
  width: 100%;
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: var(--content-primary);
  margin-bottom: 16px;
}

.list {
  padding: 0 8px;
  display: block;
  background: var(--background-primary);
  border-radius: 8px;
}

.item {
  padding: 14px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
  font-size: 14px;
  line-height: 140%;

  &:not(:last-child) {
    border-bottom: 1px solid var(--background-line);
  }

  &Title {
    flex: 1;
    font-weight: bold;
    color: var(--content-secondary);
  }

  &Value {
    flex: 1;
    text-align: right;
    font-weight: 600;
    color: var(--content-primary);
  }

  &--vertical {
    min-height: 68px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .item {
      &Title {
        margin-bottom: 6px;
        color: var(--brand-secondary);
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
      }

      &Value {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: var(--content-primary);
      }
    }
  }
  &--requirement,
  &--specialization {
    justify-content: flex-start;

    .item {
      &Title,
      &Value {
        font-weight: normal;

        font-size: 14px;
        line-height: 20px;
        color: var(--content-primary);
        text-align: left;
      }
      &Title {
        padding-top: 12px;
      }
    }
  }
}

.textBlock {
  font-size: 17px;
  & > span:first-child {
    color: var(--content-primary);
  }
}
