.wrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	min-height: var(--spinner-size);
	margin: 0 auto;
}

.spinner {
	width: var(--spinner-size);
	height: var(--spinner-size);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	animation: rotateAnimation 1s infinite linear;

	svg {
		width: 100%;
		height: 100%;
		display: block;
		color: var(--content-primary);
	}
}

@keyframes rotateAnimation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}