.wrapper {
	display: block;
	width: 100%;
	
	&--BLUE {
		background-color: #eafaff;
		
		.title {
			color: #34ceff;
		}
	}
	
	&--GREEN {
		background-color: #f5fff0;
		
		.title {
			color: #3ed857;
		}
	}
	
	&--ORANGE {
		background-color: #fff4dc;
		
		.title {
			color: #ffad33;
		}
	}
	
	&--RED {
		background-color: #fff2f2;
		
		.title {
			color: #ff3a3a;
		}
	}
	
	&--BLUE,
	&--GREEN,
	&--ORANGE,
	&--RED {
		padding: 20px;
		border-radius: 8px;
	}
	
	.title {
		font-weight: 700;
		font-size: 20px;
		line-height: 140%;
		
		+ .description {
			margin-top: 24px;
		}
	}
	
	.description {
		font-weight: 400;
		font-size: 15px;
		line-height: 140%;
		
		b {
			font-weight: 700;
		}
	}
	
	@media (max-width: 576px){
		.title {
			font-weight: bold;
			font-size: 18px;
		}
	}
}
