.wrapper {
	padding: 24px;
	position: relative;
	user-select: none;
	overflow: hidden;
	border-radius: 26px;
	
	@media (max-width: 576px){
		padding: 16px;
	}
}

.title {
	font-weight: bold;
	font-size: 23px;
	line-height: 140%;
	text-align: center;
	color: #202022;
	margin-bottom: 24px;
}

.info {
	margin-bottom: 24px;
	
	span {
		display: block;
		text-align: center;
		font-size: 15px;
		line-height: 180%;
	}
}

.buttonList {
	display: flex;
	width: 100%;
}

.spinner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	background-color: rgba(255,255,255,.7);
	user-select: none;
}