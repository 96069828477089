.wrapper {
	padding: 0 24px 24px;
	
	@media (max-width: 567px) {
		overflow: hidden;
		padding: 0 8px 8px;
	}
}

.breadcrumb {
	margin-top: 24px;
	padding: 8px 8px 8px 40px;
	min-height: 34px;
	display: flex;
	align-items: center;
	
	&NoPrev {
		padding-left: 8px;
	}
	
	@media (max-width: 567px) {
		visibility: hidden;
	}
}

.header {
	display: flex;
	align-items: center;
	
	&Content {
		width: 100%;
		margin-left: 16px;
	}
	
	@media (max-width: 567px) {
		flex-direction: column;
		padding: 0 16px;
		position: relative;
		
		&Content {
			margin-left: 0;
			margin-bottom: 24px;
		}
	}
}

.image {
	width: 300px;
	height: 300px;
	max-width: 100%;
	pointer-events: none;
	
	img {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: contain;
	}
	
	@media (max-width: 567px) {
		position: absolute;
		top: -20px;
		bottom: -20px;
		right: -80px;
		margin: auto;
		opacity: 0.1;
		pointer-events: none;
	}
}

.title {
	font-weight: 500;
	font-size: 18px;
	line-height: 150%;
	color: var(--content-primary-alt);
	margin-bottom: 14px;
}

.description {
	font-size: 14px;
	line-height: 17px;
	color: var(--content-secondary-alt);
}

.list {
	width: 100%;
	display: block;
	
	&Item {
		background: var(--background-primary);
		box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
		border-radius: 16px;
		padding: 10px 16px 10px 24px;
		min-height: 72px;
		display: flex;
		align-items: center;
		cursor: pointer;
		
		&Title {
			width: 100%;
			flex: 100%;
			font-size: 15px;
			line-height: 140%;
			color: var(--content-primary);
			margin-right: 16px;
		}
		
		&Type {
			margin-right: 16px;
			white-space: nowrap;
		}
		
		&Icon {
			width: 20px;
			min-width: 20px;
			height: 20px;
			
			svg {
				width: 100%;
				height: 100%;
				display: block;
			}
		}
		
		+ .listItem {
			margin-top: 16px;
		}
	}
	
	@media (max-width: 567px) {
		&Item {
			padding-left: 16px;
			min-height: 56px;
			align-items: flex-start;
			flex-direction: column;
			position: relative;
			overflow: hidden;
			width: 100%;
			
			&Title {
				margin: 0 0 8px;
				padding-right: 32px;
			}
			
			&Icon {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 16px;
				margin: auto;
			}
			
			+ .listItem {
				margin-top: 8px;
			}
		}
	}
}