.wrapper {
  position: relative;
  z-index: 10;

  &Open {
    .current {
      background-color: var(--content-primary);
      color: #fff;
    }

    .list {
      pointer-events: auto;
      visibility: visible;
      opacity: 1;
    }

    .item {
      transform: translateY(0);
      opacity: 1;

      &:nth-child(1) {
        transition-delay: 0s;
      }

      &:nth-child(2) {
        transition-delay: 0.1s;
      }

      &:nth-child(3) {
        transition-delay: 0.2s;
      }
    }
  }
}

.current {
  background-color: var(--background-secondary);
  border-radius: 50%;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: #222633;
  width: 40px;
  height: 40px;
  line-height: 140%;
  cursor: pointer;
  user-select: none;
  transition: all 0.1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    display: none;
  }
}

.list {
  position: absolute;
  top: calc(100% + 16px);
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

.item {
  background-color: #fff;
  border: 2px solid var(--content-primary);
  border-radius: 50%;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: var(--content-primary);
  width: 40px;
  height: 40px;
  cursor: pointer;
  user-select: none;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  transform: translateY(-5px);
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
