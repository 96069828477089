.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 12px 16px;
	border-radius: 8px;
	width: fit-content;
	
	&--green {
		background-color: var(--status-success);
	}
	
	&--red {
		background-color: var(--status-red);
	}
	
	&--orange {
		background-color: var(--status-orange);
	}
	
	&--green,
	&--red,
	&--orange {
		.title {
			color: rgba(255, 255, 255, 0.8);
		}
		
		.description {
			color: var(--content-primary-alt);
		}
	}
	
	&--white {
		border: 1px dashed rgba(255, 255, 255, 0.25);
		
		.description {
			color: var(--content-primary-alt);
		}
	}
	
	&--gray {
		border: 1px dashed var(--background-line);
		
		.description {
			color: var(--content-primary);
		}
	}
	
	&--white,
	&--gray {
		background-color: transparent;
		
		.title {
			color: var(--brand-secondary);
		}
	}
	
	.title {
		font-weight: 600;
		font-size: 11px;
		line-height: 140%;
		text-transform: uppercase;
		white-space: nowrap;
	}
	
	.description {
		font-weight: 800;
		font-size: 14px;
		line-height: 140%;
		min-height: 19px;
	}
}
