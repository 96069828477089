.wrapper {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	user-select: none;
	cursor: pointer;
	transition: .1s ease-in-out all;

	// &::before {
	// 	display: block;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	transform: translate(2.5%, -10%);
	// 	content: "";
	// 	background: url("../../assets/newyear/ice3.svg");
	// 	background-size: 95%;
	// 	background-repeat: no-repeat;
	// 	width: 100%;
	// 	overflow: hidden;
	// 	height: 100px;
	// 	z-index: 1;
	// }

	&:active {
		transform: scale(0.9);
	}

	&--variant {
		&-blue {
			background-color: var(--brand-secondary);
		}

		&-green {
			background-color: var(--status-green);
			box-shadow: 0 0 15px rgba(41, 199, 181, 0.6);
		}

		&-red {
			background-color: var(--status-red);
		}

		&-blue,
		&-green,
		&-red {
			color: var(--content-primary-alt);

			.icon {
				svg {
					path {
						fill: var(--content-primary-alt);
					}
				}
			}
		}

		&-white,
		&-white-one {
			background-color: var(--background-primary);
		}

		&-grey {
			background-color: var(--background-secondary);
		}

		&-stroke {
			background-color: var(--background-primary);
			border: 1px solid var(--background-line);
			border-radius: 6px;
		}

		&-stroke-blue {
			background-color: var(--background-primary);
			border: 1px solid var(--brand-secondary);
			border-radius: 6px;

			.icon {
				svg {
					path {
						fill: var(--brand-secondary);
					}
				}
			}
		}

		&-white,
		&-grey,
		&-stroke {
			color: var(--content-primary);
		}
	}

	&--size {
		&-middle {
			padding: 10px;
			height: 40px;

			.text {
				font-size: 14px;
				line-height: 17px;
			}
		}

		&-large {
			padding: 10px 20px;
			height: 64px;

			.text {
				font-size: 16px;
				line-height: 19px;
			}
		}
	}

	&--icon {
		&-prefix {
			.text {
				margin-left: 12px;
			}
		}

		&-suffix {
			.text {
				margin-right: 12px;
			}
		}
	}

	&--block {
		display: flex;
		width: 100%;
	}

	&--disabled {
		background-color: var(--background-secondary);
		color: var(--content-secondary);
		cursor: not-allowed;

		.icon {
			svg {
				path {
					fill: var(--content-secondary);
				}
			}
		}

		&.wrapper--variant-white-one {
			background-color: var(--background-line);
		}
	}

	&--loading {
		background-color: var(--background-secondary);
		color: var(--content-secondary);
		cursor: progress;

		&.wrapper--variant-white-one {
			border: 1px solid var(--background-line);
		}

		.icon,
		.text {
			opacity: 0;
			visibility: hidden;
		}
	}
}

.icon {
	width: 20px;
	height: 20px;

	svg {
		width: 100%;
		height: 100%;
		display: block;
	}
}

.text {
	font-weight: 500;
}

.loading {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}