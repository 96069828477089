.wrapper {
    display: block;
}


.content {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}

.title {
    display: block;
    color: var(--content-primary);
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: var(--background-secondary);
    border-radius: 6px;
    padding: 4px;
    color: var(--content-primary);

    svg {
        width: 20px;
        height: 20px;
        display: block;
    }
}

.progress {
    border-radius: 6px;
    padding: 4px;
    background: var(--background-secondary);
}