.wrapper {
	font-weight: 500;
	font-size: 13px;
	line-height: 140%;
	color: var(--content-primary-alt);
	padding: 4px 12px;
	cursor: pointer;
	user-select: none;
	border-radius: 6px;
	display: inline-block;
	white-space: nowrap;
	
	&--success {
		background-color: var(--status-success);
	}
	
	&--danger {
		background-color: var(--status-red);
	}
	
	&--warning {
		background-color: var(--status-orange);
	}
	
	&--block {
		display: block;
		text-align: center;
	}
}