.wrapper {
	background-color: var(--background-secondary);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
}

.navbar {
	position: relative;
	z-index: 2;
	width: 100%;
	background: var(--background-primary);
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.08);

	&Inner {
		display: flex;
		max-width: 1440px;
		width: 100%;
		height: 90px;
		margin: auto;
		position: relative;

		@media (max-width: 1024px) and (min-width: 576px) {
			height: 64px;
			align-items: center;
		}

		@media (max-width: 576px) {
			height: 56px;
		}
	}

	&Old {
		width: 100%;
		background-color: var(--brand-primary);
		user-select: none;
		padding: 0 24px;

		&Inner {
			width: 100%;
			height: 32px;
			margin: auto;
			max-width: 1440px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}

		&Title,
		&Link {
			font-weight: 800;
			font-size: 13px;
			line-height: 16px;
			color: var(--content-primary-alt);
		}

		&Link {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			display: flex;
			align-items: center;
			cursor: pointer;

			span {
				margin-right: 10px;
			}

			svg {
				width: 24px;
				height: 24px;
				display: block;
				transition: all .3s ease-in-out;
			}

			&:hover {
				svg {
					transform: translateX(-5px);
				}
			}
		}

		@media (max-width: 992px) {
			&Inner {
				flex-direction: column;
				text-align: center;
				height: auto;
				padding: 8px;
			}

			&Title {
				margin-bottom: 8px;
			}

			&Link {
				position: relative;
			}
		}
	}
}

.logo {
	width: 162px;
	min-width: 162px;
	height: 58px;
	margin: 16px 48px 16px 32px;
	display: flex;
	position: relative;
	gap: 10px;
	align-items: center;

	@media (max-width: 1024px) and (min-width: 576px) {
		height: 44px;
		margin: 0;
	}

	@media (max-width: 1024px) {
		display: none;
	}

	svg {
		width: 100%;
		height: 100%;
		display: block;
	}

	&Beta {
		width: 164px;
		height: 64px;
	}

	&Text {
		color: black;
		font-size: 10px;
		text-transform: uppercase;
		line-height: 150%;
		font-weight: 700;
	}
}

.menu {
	display: flex;
	align-items: center;

	@media (max-width: 576px) {
		display: block;
	}
}

.button {
	display: flex;
	align-items: center;
	margin-left: 48px;

	@media (max-width: 1024px) {
		display: none;
	}
}

.rightSide {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex: 1;

	@media (max-width: 576px) {
		display: none;
	}

	@media (max-width: 1024px) and (min-width: 576px) {
		height: 64px;
	}
}

.profile {
	display: flex;
	height: 100%;
	position: relative;
	z-index: 11;
}

.content {
	width: 100%;
	height: 100%;
	overflow: auto;
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;

	&Inner {
		flex: 1;
	}
}

.hideOnMobile {
	@media (max-width: 1024px) {
		display: none;
	}
}