.wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: #F5F7FA;
	color: #2e364b;
}

.text {
	font-size: 35px;
	margin-bottom: 50px;
}

.icon {
	width: 150px;
	height: 150px;
	margin-bottom: 50px;
	
	svg {
		width: 100%;
		height: 100%;
		display: block;
	}
}

.button {
	line-height: 1;
	text-transform: none;
	font-weight: 600;
	font-size: 12px;
	color: rgb(255, 255, 255);
	background-color: rgb(108, 95, 199);
	box-shadow: rgba(0, 0, 0, 0.05) 0 2px;
	cursor: pointer;
	border-radius: 3px;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(61, 50, 142);
	border-image: initial;
	display: block;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
	padding: 10px 14px;
	user-select: none;
}