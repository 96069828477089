.wrapper {
  padding: 28px 24px 24px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: #fff;
  margin-bottom: 28px;
}

.content {
  padding: 20px;
  background-color: var(--background-primary);
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}

.card {
  width: 100%;
  height: 220px;
  border: 1px solid lightgray;
  padding: 24px;
  border-radius: 12px;
  transition: 0.1s all ease-in-out;

  &:hover {
    transform: scale(1.03);
  }

  &:active {
    transform: scale(0.95);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 576px) {
  .content {
    padding: 16px;
  }

  &Close {
    margin-top: 12px;
    width: 100%;
  }
}
