@import './base/normalize';
@import './base/typography';
@import './base/mixins';
@import './base/colors';
@import './base/icons';
@import './base/themes';
@import './base/base';

#root {
  height: 100%;
  overflow: hidden;
}

:root {
  @include theme-light;
}

body {
  background-color: var(--background-primary);
}

.wrapper {
  height: 100%;
  overflow: hidden;
}

.grecaptcha-badge {
  display: none;
}

.hidden {
  display: none;
}

#modal {
  position: relative;
  z-index: 1050;
}

.blackAndWhite {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.blackAndWhiteInvert,
.blackAndWhiteInvert img,
.blackAndWhiteInvert embed,
.blackAndWhiteInvert video {
  -webkit-filter: grayscale(100%) invert(100%);
  -moz-filter: grayscale(100%) invert(100%);
  -ms-filter: grayscale(100%) invert(100%);
  -o-filter: grayscale(100%) invert(100%);
  filter: grayscale(100%) invert(100%);
}

.noImage {
  img,
  svg {
    visibility: hidden !important;
  }

  *:before,
  *:after {
    background-image: none !important;
  }

  [class^='Accessibility_wrapper'] {
    img,
    *:before,
    *:after,
    svg {
      visibility: visible !important;
    }
  }
}

.ReactLunaPass__luna {
  @media (max-width: 576px) {
    width: 400px !important;
    height: 400px !important;
  }
  @media (max-width: 375px) {
    width: 320px !important;
    height: 320px !important;
  }
}
