.wrapper {
	display: flex;
	border: 1px dashed var(--content-secondary);
	border-radius: 8px;
	background-color: var(--backgoround-primary);
	min-height: 200px;
	width: 100%;
	overflow: hidden;
}

.sidebar {
	border: 0;
	width: 160px;
	min-width: 160px;
	max-width: 160px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.content {
	flex: 1;
	border-left: 1px dashed var(--content-secondary);
	overflow: hidden;
}

.organization {
	font-weight: bold;
	font-size: 14px;
	line-height: 140%;
	color: var(--content-primary);
	text-align: center;
	padding: 24px;
	border-bottom: 1px dashed var(--content-secondary);
}

.details {
	padding: 8px 20px 20px;
}

.list {
	margin-bottom: 15px;

	&Item {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		display: flex;
		align-items: center;
		min-height: 56px;
		border-bottom: 1px solid var(--background-line);
		padding: 10px 16px;
		overflow-wrap: break-word;
		word-wrap: break-word;
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto;

		&Title {
			color: var(--content-secondary);
		}

		&Value {
			color: var(--content-primary);
			text-align: right;
		}

		&Title,
		&Value {
			width: 50%;
			flex: 50%;
		}
	}
}

.info {
	font-weight: 400;
	font-size: 11px;
	line-height: 140%;
	color: var(--content-primary);
	margin-bottom: 16px;

	&Title {
		font-weight: 600;
	}

	&List,
	&Item {
		display: block;
	}
}

.footer {
	display: flex;
	width: 100%;

	&Item {
		width: 100% !important;

		+.footerItem {
			margin-left: 20px;
		}
	}
}

.static {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: bolder;
	font-size: 12px;
	line-height: 140%;
	color: #202022;
	gap: 12px;
	padding: 24px;
	margin-top: 14px;
	border-top: 1px dashed #7A7B7E;
}

.title {
	margin-bottom: 24px;
	text-align: center;

	&Key {
		color: var(--content-secondary);
		font-size: 11px;
		line-height: 140%;
		font-weight: 600;
		text-transform: uppercase;
	}

	&Value {
		color: var(--content-primary);
		font-size: 14px;
		line-height: 140%;
		font-weight: bold;
	}
}

.qrImage {
	width: 120px;
	min-width: 120px;
	height: 120px;
	margin-bottom: 24px;
}

.status {
	border-radius: 8px;
	width: 100%;
	padding: 10px 12px;
	text-align: center;
	font-weight: bold;
	font-size: 11px;
	line-height: 140%;
	max-width: 120px;
	margin: 0 auto;
	text-transform: uppercase;

	&--PAID {
		background-color: #f5fff0;
		color: var(--status-success);
	}

	&--OPEN,
	&--EXPIRED,
	&--CANCELED {
		background-color: #fff0f0;
		color: var(--status-red);
	}
}