.wrapper {
	display: block;
	
	img {
		width: 100%;
		display: block;
	}
	
	&Error {
		padding: 24px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

.buttonList{
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1rem;
}

.spinner {
	padding: 50px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.title {
	font-size: 18px;
	margin: 0 auto 24px;
	max-width: 500px;
	text-align: center;
}