.title {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: var(--content-primary);
	margin-bottom: 20px;

	@media (max-width: 567px) {
		padding: 16px 16px 0;
		margin-bottom: 0;
	}
}

.description {
	display: block;
}

.list {
	width: 100%;

	&Item {
		width: 100%;
		background: var(--background-secondary);
		border-radius: 8px;
		padding: 20px;
		display: flex;
		align-items: center;

		&Number {
			border: 2px solid var(--brand-secondary);
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			font-size: 12px;
			line-height: 15px;
			color: var(--content-primary);
			width: 30px;
			min-width: 30px;
			height: 30px;
			margin-right: 12px;
		}

		&Title {
			font-weight: bold;
			font-size: 15px;
			line-height: 160%;
			color: var(--brand-secondary);
		}

		&Content {
			font-size: 14px;
			line-height: 180%;
			color: var(--content-primary);
			width: 100%;

			&Title {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 14px;
				line-height: 180%;
				color: var(--content-primary);
			}

			p {
				margin: 0;

				+p {
					margin-top: 12px;
				}
			}
		}

		+.listItem {
			margin-top: 16px;
		}
	}
}

.link {
	a {
		display: block;
		color: var(--brand-secondary);
	}
}

.extraInformation,
.specializations,
.requirements {
	margin-top: 32px;

	&Title {
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		color: var(--content-primary);
		margin-bottom: 20px;
	}

	@media (max-width: 567px) {
		margin: 16px 16px 0;
		padding-bottom: 24px;
	}
}