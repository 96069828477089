.container {
	position: fixed;
}

.wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1051;
	overflow: auto;
	display: block;
	
	&--size {
		
		&-small {
			.dialog {
				max-width: 600px;
			}
		}
		
		&-s-middle {
			.dialog {
				max-width: 800px;
			}
		}
		
		&-middle {
			.dialog {
				max-width: 1000px;
			}
		}
		
		&-large {
			.dialog {
				max-width: 1132px;
			}
		}
	}
	
	&--variant {
		&-white {
			.content {
				background: var(--background-primary);
			}
		}
	}
	
	@media (max-width: 576px){
		.dialog {
			padding: 8px;
		}
	}
}

.backdrop {
	background: rgba(0, 0, 0, .6);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1050;
}

.dialog {
	width: 100%;
	min-height: 100%;
	display: flex;
	align-items: center;
	margin: 0 auto;
	padding: 40px 0;
	pointer-events: none;
}

.content {
	width: 100%;
	//overflow: hidden;
	background: var(--background-main-gradient);
	border-radius: 26px;
	pointer-events: auto;
}