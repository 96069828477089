.list {
	background-color: var(--background-secondary);
	border-radius: 16px;
	padding: 0 8px;
	width: 100%;

	@media (max-width: 576px) {
		padding: 8px 16px;
		background-color: var(--background-primary);
	}
}

.item {
	padding: 16px 16px;
	width: 100%;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	min-height: 56px;

	@media (max-width: 576px) {
		padding: 12px 8px;
		flex-direction: column;
		align-items: flex-start;
	}
	
	&Title {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: var(--content-secondary);
		flex: auto;
		padding-right: 10px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 45%;
		overflow-wrap: break-word;
		word-wrap: break-word;
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto;

		@media (max-width: 576px) {
			color: var(--content-thirdary);
			margin-bottom: 6px;
			width: 100%;
		}
	}
	
	&Value {
		font-weight: bold;
		font-size: 14px;
		line-height: 17px;
		color: var(--content-primary);
		flex: auto;
		text-align: right;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 55%;

		@media (max-width: 576px) {
			width: 100%;
			justify-content: flex-start;
			text-align: left;
		}
	}
	
	+ .item {
		border-top: 1px solid var(--background-line);
	}
}