.list {
	display: flex;
	width: 100%;
}

.item {
	position: relative;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: var(--content-primary);
	padding: 8px;
	height: 48px;
	user-select: none;
	cursor: pointer;
	
	&:before {
		content: '';
		position: absolute;
		left: 8px;
		right: 8px;
		bottom: 0;
		width: calc(100% - 16px);
		height: 4px;
		background-color: var(--brand-primary);
		transition: all .3s ease-in-out;
		transform: scale(1, 0);
		transform-origin: bottom;
	}
	
	&Active {
		font-weight: bold;
		
		&:before {
			transform: scale(1, 1);
			visibility: visible;
		}
	}
	
	+ .item {
		margin-left: 32px;
	}
}