.wrapper {
	padding: 32px 20px 20px;
	position: relative;
	overflow: hidden;
	border-radius: 26px;
}

.title {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: var(--content-primary-alt);
	margin-bottom: 35px;
}

.list {
	width: 100%;
	display: block;
	
	&Item {
		background: var(--background-primary);
		box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
		border-radius: 16px;
		padding: 10px 16px 10px 24px;
		min-height: 72px;
		display: flex;
		align-items: center;
		cursor: pointer;
		
		&Title {
			width: 100%;
			flex: 100%;
			font-size: 15px;
			line-height: 140%;
			color: var(--content-primary);
			margin-right: 16px;
		}
		
		&Type {
			margin-right: 16px;
			white-space: nowrap;
		}
		
		&Icon {
			width: 20px;
			min-width: 20px;
			height: 20px;
			
			svg {
				width: 100%;
				height: 100%;
				display: block;
			}
		}
		
		+ .listItem {
			margin-top: 16px;
		}
	}
	
	@media (max-width: 567px) {
		&Item {
			padding-left: 16px;
			min-height: 56px;
			align-items: flex-start;
			flex-direction: column;
			position: relative;
			overflow: hidden;
			width: 100%;
			
			&Title {
				margin: 0 0 8px;
				padding-right: 32px;
			}
			
			&Icon {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 16px;
				margin: auto;
			}
			
			+ .listItem {
				margin-top: 8px;
			}
		}
	}
}

.spinner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	background-color: rgba(255,255,255,.5);
	user-select: none;
}