.wrapper {
	display: inline-block;
	padding: 4px 10px;
	border-radius: 6px;
	letter-spacing: 1px;
	font-size: 13px;
	line-height: 140%;
	user-select: none;
	cursor: pointer;
	
	&--variant {
		&-gray {
			font-weight: 600;
			background-color: rgba(255, 255, 255, 0.8);
			backdrop-filter: blur(25px);
			color: var(--content-primary);
		}
		
		&-dark-gray {
			font-weight: 500;
			background-color: rgba(255, 255, 255, 0.25);
			backdrop-filter: blur(25px);
			color: var(--content-secondary-alt);
		}
		
		&-white {
			font-weight: 500;
			background: var(--background-secondary);
			color: var(--content-primary);
		}
		
		&-blue {
			font-weight: 600;
			background-color: #eafaff;
			color: var(--brand-secondary);
		}
	}
}