.wrapper {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border-style: dashed;
    border-color: lightgrey;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;   
}

.document {
    padding: 10px;
    border-radius: 8px;
    border-style: dashed;
    border-color: lightgrey;


    &Action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;

        img {
            width: 150px;
        }
    }

}