.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	padding: 0;
	margin: 0;
}

.item {
	display: block;
	
	&Link {
		min-width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 6px;
		cursor: pointer;
		user-select: none;
		font-weight: 500;
		font-size: 16px;
		line-height: 180%;
		color: var(--content-primary);
		
		svg {
			width: 100%;
			height: 100%;
			display: block;
		}
	}
	
	&Active {
		display: block;
		
		&Link {
			color: var(--brand-secondary);
		}
	}
	
	&Prev,
	&Next {
		display: block;
		width: 24px;
	}
	
	&PrevLink {
		margin-right: 12px;
	}
	
	&NextLink {
		margin-left: 12px;
	}
	
	&Disabled {
		opacity: .3;
	}
}