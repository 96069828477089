.wrapper {
	padding: 32px 16px 16px;
	
	@media (max-width: 576px){
		padding: 16px 0 0;
	}
}

.profile {
	&Wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-bottom: 32px;
	}
	
	&Name {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: var(--content-primary-alt);
		text-align: center;
	}
	
	&Company {
		font-weight: bold;
		font-size: 14px;
		line-height: 17px;
		color: var(--content-secondary-alt);
		text-align: center;
		margin-top: 8px;
	}
}

.tab {
	
	&Wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 24px;
		user-select: none;
		
		&Inner {
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--content-thirdary-alt);
			border-radius: 40px;
			padding: 2px;
		}
	}
	
	&Item {
		border-radius: 40px;
		padding: 10px 24px;
		text-align: center;
		cursor: pointer;
		transition: all .2s ease-in-out;
		white-space: nowrap;
		width: calc(100% + 20px);
		color: var(--content-primary-alt);
		
		&Title {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
		}
		
		&Icon {
			display: none;
			width: 24px;
			height: 24px;
		}
		
		&Active {
			background: var(--background-primary);
			box-shadow: 0 2px 15px rgba(9, 17, 38, 0.1);
			color: var(--content-primary);
		}
	}
	
	@media (max-width: 576px){
		&Item {
			
			&Title {
				display: none;
			}
			
			&Icon {
				display: block;
			}
		}
	}
}

.content {
	background-color: var(--background-primary);
	min-height: 464px;
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
	border-radius: 16px;
}

.details {
	padding: 8px 16px;
	background-color: var(--background-primary);
}