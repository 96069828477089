.list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -12px;
	margin-right: -12px;
	
	@media (max-width: 992px) {
		flex-wrap: nowrap;
		overflow-x: scroll;
		padding: 20px 0;
	}
	
	@media (max-width: 576px){
		margin-left: 0;
		margin-right: 0;
	}
	
	&Wrapper {
		display: block;
		width: 100%;
		margin-bottom: 64px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&Title {
		font-weight: bold;
		font-size: 23px;
		line-height: 28px;
		color: var(--content-primary);
		margin-bottom: 28px;

		@media (max-width: 992px){
			font-size: 16px;
			line-height: 19px;
			flex: 1 1;
			text-align: center;
		}
	}
	
	&Item {
		width: 50%;
		max-width: 50%;
		flex: 50%;
		margin-bottom: 24px;
		padding: 0 12px;
		
		&:last-child,
		&:nth-last-child(2):nth-child(odd) {
			margin-bottom: 0;
		}
		
		@media (max-width: 992px){
			width:316px;
			min-width: 316px;
			margin-bottom: 16px;
			padding: 0 8px;
			
			&:last-child,
			&:nth-last-child(2):nth-child(odd) {
				margin-bottom: 16px;
			}
		}
	}
	
	&Full {
		.listItem {
			width: 100%;
			max-width: 100%;
			flex: 100%;
			margin-bottom: 12px;
			
			&:nth-last-child(2):nth-child(odd) {
				margin-bottom: 12px;
			}
		}
	}
}

.categoryItem {
	background: var(--background-primary);
	box-shadow: 0 2px 30px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	padding: 33px 20px;
	display: flex;
	align-items: center;
	height: 100%;
	cursor: pointer;
	user-select: none;

	@media (max-width: 992px){
		flex-direction: column;
		width: 300px;
		max-width: 300px;
		min-width: 300px;
		min-height: 100%;
		padding: 16px;
		box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
	}
	
	&Image {
		width: 110px;
		min-width: 110px;
		height: 104px;
		
		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
			object-position: center;
		}
	}
	
	&Content {
		margin-left: 20px;
		@media (max-width: 992px){
			margin-left: 0;
		}
	}
	
	&Type {
		margin-bottom: 16px;
	}
	
	&Title {
		font-weight: bold;
		font-size: 21px;
		line-height: 25px;
		color: var(--content-primary);
		margin-bottom: 12px;

		@media (max-width: 992px){
			font-size: 16px;
		}
	}
	
	&Description {
		font-weight: 500;
		font-size: 17px;
		line-height: 150%;
		color: var(--content-secondary);

		@media (max-width: 992px){
			font-size: 14px;
		}
	}
}

.subCategoryItem {
	background: var(--background-primary);
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
	border-radius: 16px;
	padding: 10px 16px 10px 24px;
	min-height: 72px;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;

	@media (max-width: 992px){
		height: 100%;
	}
	
	&Title {
		width: 100%;
		flex: 100%;
		font-size: 15px;
		line-height: 140%;
		color: var(--content-primary);
		margin-right: 16px;
	}
	
	&Type {
		margin-right: 16px;
		white-space: nowrap;

		@media (max-width: 992px){
			display: none;
		}
	}
	
	&Icon {
		width: 20px;
		min-width: 20px;
		height: 20px;
		
		svg {
			width: 100%;
			height: 100%;
			display: block;
		}
	}
	
	+ .listItem {
		margin-top: 16px;
	}
}

.empty {
	width: 100%;
	max-width: 335px;
	margin: 0 auto 48px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	&Icon {
		width: 112px;
		height: 112px;
		border-radius: 50%;
		background-color: var(--background-primary);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
		
		svg {
			width: 64px;
			height: 64px;
			display: block;
		}
	}
	
	&Title {
		font-weight: bold;
		font-size: 20px;
		line-height: 140%;
		text-align: center;
		color: var(--content-primary);
		margin-bottom: 20px;
	}
	
	&Description {
		font-size: 14px;
		line-height: 170%;
		text-align: center;
		color: var(--content-secondary);
	}
}