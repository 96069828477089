.wrapper {
	width: 100%;
	display: block;
}

.title {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: var(--content-primary);
	margin-bottom: 20px;
}

.list {
	width: 100%;
	display: block;
}

.item {
	width: 100%;
	background: var(--background-secondary);
	border-radius: 8px;
	padding: 20px;
	display: flex;
	align-items: center;
	
	&Number {
		border: 2px solid var(--brand-secondary);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		font-size: 12px;
		line-height: 15px;
		color: var(--content-primary);
		width: 30px;
		min-width: 30px;
		height: 30px;
	}
	
	&Content {
		margin-left: 12px;
	}
	
	&Title {
		font-weight: bold;
		font-size: 15px;
		line-height: 160%;
		color: var(--brand-secondary);
	}
	
	&Description {
		font-size: 14px;
		line-height: 180%;
		color: var(--content-primary);
		width: 100%;
		
		p {
			margin: 0;
			
			+ p {
				margin-top: 12px;
			}
		}
	}
	
	+ .item {
		margin-top: 16px;
	}
}

@media (max-width: 576px){
	.item {
		flex-direction: column;
		align-items: flex-start;
		
		&Number {
			margin-bottom: 12px;
		}
		
		&Content {
			margin-left: 0;
		}
	}
}