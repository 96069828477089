.wrapper {
  background: #fff;
  box-shadow: 0px -2px 25px rgba(0, 0, 0, 0.05);
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 48px 40px;

  @media (max-width: 1100px) {
    padding: 16px 32px;
  }

  // &::before {
  // 	position: absolute;
  // 	top: -5%;
  // 	left: 0;
  // 	content: "";
  // 	background: url("../../../../assets/newyear/ice.svg");
  // 	background-size: 15em 80px;
  // 	background-repeat: repeat-x;
  // 	width: 100%;
  // 	height: 100px;
  // 	z-index: 99;
  // }
}

.content {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 44px;
  max-width: 1100px;

  @media (max-width: 1100px) {
    display: none;
  }
}

.logo {
  width: 109px;
  height: 162px;
  align-self: center;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.menu {
  display: block;

  &Title {
    font-weight: 900;
    font-size: 15px;
    line-height: 160%;
    color: var(--content-primary);
    margin-bottom: 16px;
  }

  &List {
    -webkit-columns: 2 160px;
    -moz-columns: 2 160px;
    columns: 2 160px;
    -webkit-column-gap: 64px;
    -moz-column-gap: 64px;
    column-gap: 64px;
  }

  &Item {
    min-width: 160px;
    max-width: 250px;
    display: block;
    font-size: 15px;
    line-height: 160%;
    color: var(--content-primary);
    margin-bottom: 16px;
    cursor: pointer;
  }
}

.help {
  display: block;

  &Title {
    font-weight: 900;
    font-size: 15px;
    line-height: 160%;
    color: var(--content-primary);
    margin-bottom: 16px;
  }

  &List {
    display: block;
  }

  &Item {
    background-color: var(--status-info);
    border-radius: 8px;
    padding: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &Icon {
      width: 24px;
      height: 24px;
      color: var(--brand-secondary);
      margin-right: 12px;
    }

    &Value {
      font-weight: 500;
      font-size: 15px;
      line-height: 160%;
      color: var(--content-primary);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.store {
  display: block;

  &Title {
    font-weight: 900;
    font-size: 15px;
    line-height: 160%;
    color: var(--content-primary);
    margin-bottom: 28px;
  }

  &List {
    display: block;
  }

  &Item {
    margin-bottom: 28px;
    width: 170px;
    height: 52px;
    display: block;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.copyright {
  font-size: 12px;
  line-height: 160%;
  color: var(--content-thirdary);
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
}

.organization {
  &List {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    // @media (max-width: 992px) {
    // 	flex-wrap: wrap;
    // }
  }

  &Item {
    position: relative;
    height: 100%;
    max-height: 102px;
    margin-left: 32px;
    margin-right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: block;
    }

    img {
      display: block;
      object-fit: contain;
    }

    &:nth-child(1) {
      img {
        width: 96px;
        height: 62px;
      }
    }

    &:nth-child(2) {
      img {
        width: 82px;
        height: 92px;
      }
    }

    &:nth-child(3) {
      svg {
        width: 70px;
      }
    }

    &:nth-child(4) {
      img {
        width: 50px;
        height: 102px;
      }
    }

    @media (max-width: 992px) {
      width: 50%;
      margin: 0;

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 32px;
      }
    }
  }
}
