html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	overscroll-behavior: none;
}

html {
	vertical-align: baseline;
	overflow: hidden;
}

body {
	font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

a {
	outline: none;
	text-decoration: none;
	color: inherit;
}

button {
	background: #fff;
	border: 0;
	outline: none;
	padding: 0;
	margin: 0;
}

input {
	outline: none;
}

form {
	width: 100%;
}