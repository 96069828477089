.wrapper {
	width: 100%;
	display: block;
	margin-bottom: 24px;
	position: relative;
	
	&Disabled {
		cursor: not-allowed;
		
		.label {
			cursor: not-allowed;
			color: var(--content-thirdary);
			
			span {
				color: var(--content-thirdary);
			}
		}
		
		.option {
			&List {
				pointer-events: none;
				opacity: .6;
			}
		}
	}
}

.label {
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	color: var(--content-primary);
	cursor: pointer;
	z-index: 1;
	user-select: none;
	margin-bottom: 8px;
	
	span {
		color: var(--status-red);
		margin-right: 8px;
	}
}

.option {
	&List {
		background: var(--background-secondary);
		border-radius: 8px;
		padding: 0 8px;
	}
	
	&Item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 56px;
		padding: 16px 8px;
		cursor: pointer;
		user-select: none;
		
		&:not(:last-child){
			border-bottom: 1px solid var(--background-line);
		}
		
		&Label {
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: var(--content-primary);
		}
		
		&Value {
			display: block;
		}
	}
	
	&Input {
		display: none;
		visibility: hidden;
		opacity: 0;
		
		&View {
			width: 24px;
			min-width: 24px;
			height: 24px;
			cursor: pointer;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			border-radius: 50%;
			
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 12px;
				height: 12px;
				background-color: var(--content-primary-alt);
				opacity: 0;
				visibility: hidden;
				transition: all .1s ease-in-out;
				z-index: 1;
				border-radius: 50%;
			}
			
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 20px;
				height: 20px;
				border: 2px solid var(--content-thirdary);
				transition: all .1s ease-in-out;
				z-index: 0;
				border-radius: 50%;
			}
		}
		
		&:checked + .optionInputView {
			&:before {
				opacity: 1;
				visibility: visible;
			}
			&:after {
				border-color: var(--brand-secondary);
				background-color: var(--brand-secondary);
			}
		}
	}
}

.errorLabel {
	position: absolute;
	left: 0;
	top: 100%;
	padding-top: 5px;
	display: block;
	font-weight: bold;
	font-size: 12px;
	line-height: 140%;
	color: var(--status-red);
}
