.wrapper {
	width: 100%;
	height: 56px;
	background-color: var(--background-primary);
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.08);
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	
	@media (min-width: 1025px) {
		display: none;
	}
	
	@media (max-width: 1025px) and (min-width: 576px) {
		width: auto;
		background: none;
		box-shadow: none;
	}
}

.logo {
	width: 56px;
	min-width: 56px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	svg {
		display: block;
		width: 40px;
		height: 40px;
	}
	
	&Beta {
		svg {
			width: 44px;
			height: 44px;
		}
	}
	
	&Horizontal {
		width: 162px;
		height: 58px;
		display: none;
		
		@media (max-width: 1025px) and (min-width: 576px) {
			width: 122px;
			height: 44px;
			display: block;
		}
		
		svg {
			width: 100%;
			height: 100%;
			display: block;
		}
		
		&Beta {
			width: 124px;
			height: 46px;
		}
	}
	
	@media (max-width: 1025px) and (min-width: 576px) {
		display: none;
	}
}

.button {
	width: 56px;
	min-width: 56px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	
	svg {
		width: 24px;
		height: 24px;
		display: block;
	}
	
	&Profile {
		@media (min-width: 576px) {
			display: none;
		}
	}
}

.dropdown {
	position: absolute;
	left: 8px;
	top: 64px;
	background: var(--background-primary);
	box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
	border-radius: 16px;
	width: 100%;
	max-width: 320px;
	opacity: 0;
	visibility: hidden;
	transform: translateY(-10px);
	transition: all .2s ease-in-out;
	
	&Profile {
		left: auto;
		right: 8px;
		max-width: 240px;
	}
	
	@media (max-width: 1025px) and (min-width: 576px) {
		top: 72px;
	}
}

.list {
	width: 100%;
	padding: 0 16px;
	
	&Item {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		min-height: 56px;
		border-top: 1px solid var(--background-line);
		padding: 8px;
		cursor: pointer;
		
		&:last-child {
			border-bottom: 1px solid var(--background-line);
		}
		
		&Active {
			color: var(--brand-secondary);
		}
		
		&Title {
			display: block;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: var(--content-primary);
		}
		
		&Icon {
			width: 20px;
			min-width: 20px;
			height: 20px;
			margin-left: 12px;
			color: var(--content-primary);
			
			svg {
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	}
	
	&Profile {
		.listItem {
			justify-content: flex-end;
		}
	}
}

.language {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 12px;
	
	&Item {
		width: 40px;
		min-width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		font-size: 14px;
		line-height: 14px;
		color: var(--content-primary);
		margin: 0 6px;
		background-color: var(--background-secondary);
		border-radius: 50%;
		cursor: pointer;
		transition: all .3s ease-in-out;
		
		&Active {
			background-color: var(--brand-secondary);
			color: var(--content-primary-alt);
		}
	}
}

.profile {
	padding: 12px 24px;
	margin-bottom: 16px;
	
	&Name {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: var(--content-primary);
		margin-bottom: 4px;
		text-align: right;
	}
	
	&Company {
		font-weight: bold;
		font-size: 14px;
		line-height: 17px;
		color: var(--content-secondary);
		text-align: right;
	}
}