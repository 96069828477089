.wrapper {
	width: 100%;
	height: 500px;
	position: relative;
	
	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		height: 40px;
		z-index: 10;
		pointer-events: none;
	}
	
	&:before {
		top: 0;
		bottom: auto;
		background: linear-gradient(180deg, var(--background-secondary) 9%, rgba(255, 255, 255, 0) 78%);
		z-index: 10;
	}
	
	&:after {
		top: auto;
		bottom: 0;
		height: 80px;
		background: linear-gradient(0deg, var(--background-secondary) 9%, rgba(255, 255, 255, 0) 78%);
	}
	
	&Inner {
		width: 100%;
		height: 100%;
		padding: 16px;
		outline: none;
		overflow: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;
		
		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}
}

.spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.empty {
	padding: 48px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	
	&Icon {
		width: 112px;
		height: 112px;
		border-radius: 50%;
		background-color: var(--background-primary);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
		
		svg {
			width: 64px;
			height: 64px;
			display: block;
		}
	}
	
	&Title {
		font-weight: bold;
		font-size: 20px;
		line-height: 140%;
		text-align: center;
		color: var(--content-primary);
		margin-bottom: 20px;
	}
	
	&Subtitle {
		font-size: 14px;
		line-height: 170%;
		text-align: center;
		color: var(--content-secondary);
	}
}

.date {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: var(--content-thirdary);
	margin: 12px 0 12px;
	
	&First {
		margin-top: 0;
	}
}

.item {
	background: var(--background-primary);
	border-radius: 16px;
	padding: 12px;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
	margin-bottom: 12px;
	
	&Content {
		width: 100%;
		margin-right: 6px;
	}
	
	&Title {
		font-size: 14px;
		line-height: 150%;
		display: flex;
		align-items: center;
		color: var(--content-primary);
		margin-bottom: 6px;
		
		b {
			font-weight: bold;
		}
	}
	
	&Description {
		font-weight: 500;
		font-size: 13px;
		line-height: 150%;
		color: var(--content-secondary);
		margin-bottom: 6px;
	}
	
	&Image {
		width: 40px;
		min-width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: var(--background-secondary);
		display: flex;
		align-items: center;
		justify-content: center;
		
		img {
			width: 24px;
			height: 24px;
			object-fit: contain;
			display: block;
			
			&:not([src]) {
				visibility: hidden;
			}
		}
	}
	
	&Footer {
		display: flex;
	}
	
	&Link {
		font-weight: 600;
		font-size: 10px;
		line-height: 150%;
		color: var(--brand-secondary);
		margin-right: 12px;
	}
	
	&Time {
		font-weight: 600;
		font-size: 10px;
		line-height: 150%;
		text-transform: uppercase;
		color: var(--content-thirdary);
	}
	
	+ .date {
		margin-top: 24px;
	}
}