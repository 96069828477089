@mixin theme-light {
  --brand-primary: #{$light_brand_primary};
  --brand-secondary: #{$light_brand_secondary};

  --background-primary: #{$light_background_primary};
  --background-secondary: #{$light_background_secondary};
  --background-line: #{$light_background_line};
  --background-main-gradient: #{$light_background_main_gradient};
  --background-success-grident: #{$light_background_success_gradient};

  --content-primary: #{$light_content_primary};
  --content-primary-alt: #{$light_content_primary_alt};
  --content-secondary: #{$light_content_secondary};
  --content-secondary-alt: #{$light_content_secondary_alt};
  --content-thirdary: #{$light_content_thirdary};
  --content-thirdary-alt: #{$light_content_thirdary_alt};

  --status-success: #{$light_status_success};
  --status-green: #{$light_status_green};
  --status-green-light: #{$light_status_green_light};
  --status-info: #{$light_status_info};
  --status-red: #{$light_status_red};
  --status-red-light: #{$light_status_red_light};
  --status-orange: #{$light_status_orange};
  --status-orange-light: #{$light_status_orange_light};
  --status-purple: #{$light_status_purple};
  --status-purple-light: #{$light_status_purple_light};
  --status-cyan: #{$light_status_cyan};
  --status-cyan-light: #{$light_status_cyan_light};

  --field-background: #{$light_field_background};
  --field-background-active: #{$light_field_background_active};
  --field-placeholder: #{$light_field_placeholder};
  --field-focus-shadow: #{$light_field_focus_shadow};
}
