.wrapper {
	position: relative;
	user-select: none;
	
	&Open {
		display: flex;
	}
}

.button {
	background-color: var(--background-secondary);
	border-radius: 50%;
	text-align: center;
	color: #222633;
	width: 40px;
	height: 40px;
	cursor: pointer;
	user-select: none;
	transition: all 0.1s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 24px;
	
	@media (max-width: 1024px) {
		display: none;
	}
	
	svg {
		width: 20px;
		min-width: 20px;
		height: 20px;
		min-height: 20px;
		display: block;
	}
}

.dropdown {
	background: var(--background-secondary);
	border: 1px solid var(--background-line);
	box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
	border-radius: 16px;
	position: absolute;
	top: calc(100% + 10px);
	left: 50%;
	width: 290px;
	max-height: 625px;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	transform: translateX(-50%) translateY(-20px);
	transition: all .2s ease-in-out;
	will-change: transform;
	display: block;
	padding: 24px;
}

.title {
	font-size: 14px;
	font-weight: 600;
	color: #222633;
	margin-bottom: 12px;
}

.appearance {
	width: 50px;
	height: 50px;
	text-align: center;
	vertical-align: middle;
	display: block;
	font-size: 27px;
	line-height: 50px;
	font-weight: bolder;
	cursor: pointer;
	border: 2px solid #353535;
	color: #fff;
	margin-bottom: 24px;
	user-select: none;
	
	+ .appearance {
		margin-left: 12px;
	}
	
	&Normal {
		background: #00a2e0;
		border-color: #034e6b;
	}
	
	&BlackAndWhite {
		background: #828282;
		border-color: #353535;
	}
	
	&Dark {
		background: #3e3e3e;
		border-color: #777777;
	}
	
	&Image {
		border-color: #00a2e0;
		background-size: 24px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cdefs/%3E%3Cpath fill='%23212121' d='M3.28034 2.21968c-.29289-.2929-.76777-.29291-1.06066-.00002-.2929.29289-.29291.76777-.00002 1.06066l1.26361 1.26363C3.17684 5.03983 3 5.62427 3 6.25001V17.75C3 19.5449 4.45507 21 6.25 21h11.5c.6257 0 1.21-.1768 1.7059-.4831l1.2635 1.2636c.2929.2929.7678.2929 1.0607 0 .2929-.2929.2929-.7678 0-1.0607L3.28034 2.21968zM12.0681 13.1289l6.2058 6.206c.019.0223.0379.0445.0566.0665-.1816.0639-.377.0986-.5805.0986H6.25c-.20387 0-.3996-.0349-.58155-.0989l5.80675-5.6863.5929-.5859zm-.9492-.9492c-.2511.1068-.4867.2612-.6931.4633l-5.82254 5.7007C4.53643 18.1583 4.5 17.9584 4.5 17.75V6.25001c0-.2063.0357-.40426.10125-.58805l6.51765 6.51774zM19.5 16.3183V6.25001c0-.9665-.7835-1.75-1.75-1.75H7.68194L6.18265 3.00069c.0224-.00045.04485-.00068.06735-.00068h11.5c1.7949 0 3.25 1.45507 3.25 3.25V17.75c0 .0226-.0002.0452-.0007.0676L19.5 16.3183z'/%3E%3Cpath fill='%23212121' d='M15.2521 6.50001c1.2438 0 2.2521 1.00831 2.2521 2.25211 0 1.24381-1.0083 2.25208-2.2521 2.25208S13 9.99593 13 8.75212c0-1.2438 1.0083-2.25211 2.2521-2.25211zm0 1.5c-.4154 0-.7521.33673-.7521.75211 0 .41539.3367.75212.7521.75212s.7521-.33673.7521-.75212c0-.41538-.3367-.75211-.7521-.75211z'/%3E%3C/svg%3E") !important;
		
		&Exists {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs/%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23212121' fill-rule='nonzero'%3E%3Cpath d='M17.75 3C19.5449254 3 21 4.45507456 21 6.25v11.5c0 1.7949254-1.4550746 3.25-3.25 3.25H6.25C4.45507456 21 3 19.5449254 3 17.75V6.25C3 4.45507456 4.45507456 3 6.25 3h11.5zm.580538 16.401407l-5.8058707-5.6865888c-.2650503-.2595627-.6745422-.2831753-.9658723-.0708268l-.0835916.0707834-5.80675242 5.6862764C5.85040089 19.4651384 6.04612926 19.5 6.25 19.5h11.5c.2034932 0 .3988742-.0347326.580538-.098593l-5.8058707-5.6865888 5.8058707 5.6865888zM17.75 4.5H6.25c-.96649831 0-1.75.78350169-1.75 1.75v11.5c0 .2084012.03642824.4082941.10326447.5936585l5.82251943-5.7006666c.8330825-.8156998 2.1416774-.8544901 3.0199857-.1164086l.1284058.1165388 5.8221966 5.7015437C19.4634397 18.1590183 19.5 17.9587787 19.5 17.75V6.25c0-.96649831-.7835017-1.75-1.75-1.75zm-2.497885 2c1.2438087 0 2.252115 1.00830622 2.252115 2.25211499 0 1.24380876-1.0083063 2.25211501-2.252115 2.25211501C14.0083062 11.00423 13 9.99592375 13 8.75211499 13 7.50830622 14.0083062 6.5 15.252115 6.5zm0 1.5C14.8367333 8 14.5 8.33673335 14.5 8.75211499c0 .41538163.3367333.75211498.752115.75211498.4153816 0 .752115-.33673335.752115-.75211498C16.00423 8.33673335 15.6674966 8 15.252115 8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
		}
	}
	
	&List {
		display: flex;
	}
}

.fontSize {
	&List {
		display: flex;
		margin-bottom: 24px;
	}
	
	&Item {
		font-size: 10px;
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #3e3e3e;
		cursor: pointer;
		user-select: none;
		line-height: 32px;
		
		&-1 {
			font-size: 16px;
		}
		
		&-2 {
			font-size: 24px;
		}
		
		+ .fontSizeItem {
			margin-left: 12px;
		}
		
		&Active {
			background-color: #3e3e3e;
			color: #fff;
		}
	}
}
