.wrapper {
	padding: 24px;
	overflow: hidden;
	position: relative;
	border-radius: 26px;
}

.title {
	font-weight: bold;
	font-size: 23px;
	line-height: 140%;
	text-align: center;
	color: var(--content-primary);
	margin-bottom: 12px;
}

.description {
	font-size: 15px;
	line-height: 180%;
	color: var(--content-secondary);
	margin-bottom: 24px;
}

.button {
	display: block;
	width: 100%;
	
	&:first-child {
		margin-right: 6px;
	}
	
	&:last-child {
		margin-left: 6px;
	}
	
	&List {
		display: flex;
	}
}

.spinner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	background-color: rgba(255,255,255,.7);
	user-select: none;
}