.wrapper {
  display: block;
}

.empty {
  text-align: center;
}

.view {
  padding: 2rem;
}

.actions {
  margin-bottom: 12px;
}
