.wrapper {
	padding: 56px 16px 16px;
	user-select: none;
	overflow: hidden;

	@media (max-width: 576px) {
		padding: 14px 0 0;
		position: relative;
	}
}

.headerMobileTitle {
	font-weight: 800;
	font-size: 20px;
	line-height: 140%;
	margin-bottom: 26px;
	display: none;
	color: var(--content-primary-alt);
	text-align: center;
	margin-top: 10px;
	
	@media (max-width: 576px) {
		display: block;
	}
}

.spinner {
	margin: 100px 0;
}

.header {
	display: flex;
	align-items: center;

	@media (max-width: 992px) {
		margin-bottom: 20px;
	}

	@media (max-width: 576px){
    	padding: 0 16px;
	}
	
	&Image {
		width: 256px;
		min-width: 256px;
		height: 256px;
		pointer-events: none;
		
		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: contain;
		}
		
		@media (max-width: 992px) {
			position: absolute;
			opacity: 0.1;
			right: -50px; 
		}
	}
	
	&Content {
		width: 100%;
		margin-left: 40px;

		@media (max-width: 992px) {
			margin-left: 0;
		}
	}
}

.category {
	font-size: 14px;
	line-height: 17px;
	color: var(--content-secondary-alt);
	margin-bottom: 14px;
}

.title {
	font-weight: 500;
	font-size: 18px;
	line-height: 150%;
	color: var(--content-primary-alt);
	margin-bottom: 14px;
}

.tags {
	display: flex;
	margin-bottom: 14px;
	row-gap: 10px;
	flex-wrap: wrap;
}

.info {
	display: flex;
    align-items: flex-start;
    overflow: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
	
	@media (max-width: 576px){
		width: calc(100% + 32px);
		margin-left: -16px;
		margin-right: -16px;
		padding-left: 16px;
		padding-right: 16px;
		
		&:after {
			content: '';
			width: 8px;
			min-width: 8px;
			height: 8px;
			display: block;
		}
	}
}

.content {
	background: var(--background-primary);
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
	border-radius: 16px;
	padding: 24px;

	@media (max-width: 576px) {
		padding: 10px;
	}
}

.button {
	&List {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 32px;
	}
}

.detail {
	&Title {
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		color: var(--content-primary);
		margin: 24px 0 12px;
	}
}

.field {
	&File {
		display: flex;
		align-items: center;
		
		&Title {
			display: block;
			color: var(--content-primary);
			max-width: 250px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		
		&Icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 28px;
			height: 28px;
			background: var(--background-secondary);
			border-radius: 6px;
			padding: 4px;
			color: var(--content-primary);
			margin-left: 10px;
			
			svg {
				width: 20px;
				height: 20px;
				display: block;
			}
		}
	}
}