.wrapper {
	padding: 0 24px 24px;

	@media (max-width: 567px) {
		overflow: hidden;
		padding: 0;
	}
}

.breadcrumb {
	margin: 24px 0 32px;
	padding: 8px 8px 8px 40px;
	min-height: 34px;
	display: flex;
	align-items: center;

	@media (max-width: 567px) {
		visibility: hidden;
		margin-bottom: 16px;
	}
}

.title {
	font-weight: 600;
	font-size: 18px;
	line-height: 140%;
	color: var(--content-primary-alt);
	text-align: center;
	margin-bottom: 20px;

	@media (max-width: 567px) {
		padding: 0 8px;
	}
}

.info {
	display: flex;
	justify-content: center;
	width: calc(100% + 48px);
	margin: 0 -24px 24px;

	@media (max-width: 567px) {
		padding: 0 8px;
	}

	&Inner {
		display: flex;
		overflow-x: auto;
		white-space: nowrap;
		scrollbar-width: none;
		-ms-overflow-style: none;
		padding: 10px 24px;

		&:after {
			content: '';
			width: 24px;
			min-width: 24px;
			height: 24px;
			display: block;
		}

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}
}

.content {
	background: var(--background-primary);
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
	border-radius: 16px;
	padding: 16px;

	@media (max-width: 567px) {
		padding: 0;
	}
}