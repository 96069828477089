.wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding: 24px;
	display: flex;
	justify-content: space-between;
	pointer-events: none;
	
	@media (max-width: 576px){
		padding: 16px;
	}
	
	&--variant {
		&-dark {
			.back,
			.close {
				color: var(--content-primary);
			}
		}
	}

	&--size {
		&-small {
			padding:18px;
		}
	}
}

.block {
	display: block;
}

.back,
.close {
	width: 33px;
	height: 33px;
	pointer-events: auto;
	cursor: pointer;
	user-select: none;
	color: var(--content-primary-alt);
	
	svg {
		display: block;
		width: 100%;
		height: 100%;
	}
	
	@media (max-width: 576px){
		display: flex;
		align-items: center;
		justify-content: center;
		
		svg {
			width: 24px;
			height: 24px;
		}
	}
}

.close {
	&Mobile {
		display: none;
	}
	
	@media (max-width: 576px){
		display: none;
		
		&Mobile {
			display: flex;
		}
	}
}