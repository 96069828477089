.wrapper {
	display: block;
	overflow: hidden;
	
	&--variant {
		&-white {
			.item {
				background-color: var(--content-thirdary-alt);
				
				&Active {
					background-color: var(--content-primary-alt);
				}
			}
		}
		
		&-blue {
			.item {
				background-color: var(--background-line);
				
				&Active {
					background-color: var(--brand-secondary);
				}
			}
		}
	}
	
	&--size {
		&-small {
			.item {
				width: 12px;
				height: 4px;
			}
		}
		
		&-middle {
			.item {
				width: 42px;
				height: 8px;
			}
		}
		
		&-large {
			.item {
				width: 53px;
				height: 8px;
			}
		}
	}
	
	&--full {
		.item {
			width: 100%;
		}
	}
}

.title {
	font-weight: bold;
	font-size: 20px;
	line-height: 140%;
	margin-bottom: 16px;
	color: var(--content-primary-alt);
	word-spacing: 5px;
}

.list {
	display: flex;
	overflow: hidden;
}

.item {
	width: 100%;
	border-radius: 14px;
	
	+ .item {
		margin-left: 8px;
	}
}