.wrapper {
	position: relative;
	display: block;
	margin-bottom: 24px;
	width: 100%;
	
	&Inner {
		position: relative;
		border-radius: 8px;
		display: flex;
		align-items: center;
		padding: 16px;
		cursor: pointer;
		height: 56px;
		max-height: 56px;
		transition: all .2s ease-in-out;
		border: 1px solid var(--content-thirdary);
	}
	
	&--error {
		.wrapperInner {
			border: 1px solid var(--status-red);
		}
	}
	
	&--disabled {
		.label,
		.value {
			color: var(--content-thirdary);
			
			span {
				color: var(--content-thirdary);
			}
		}
		
		.icon,
		.buttonList {
			opacity: .3;
			pointer-events: none;
		}
	}
}


.label {
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	color: var(--content-primary);
	cursor: pointer;
	z-index: 1;
	user-select: none;
	margin-bottom: 8px;
	
	span {
		color: var(--status-red);
		margin-right: 8px;
	}
}

.input {
	display: none;
}

.icon {
	width: 32px;
	height: 32px;
	background: var(--brand-secondary);
	border-radius: 6px;
	padding: 4px;
	color: var(--content-primary-alt);
	margin-right: 10px;
	cursor: pointer;
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
	
	svg {
		width: 20px;
		height: 20px;
		display: block;
	}
}

.value {
	display: block;
	flex: 1;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.placeholder {
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	color: var(--field-placeholder);
	width: 100%;
}

.button {
	width: 28px;
	height: 28px;
	background: var(--background-secondary);
	border-radius: 6px;
	padding: 4px;
	color: var(--content-primary);
	margin-left: 10px;
	cursor: pointer;
	user-select: none;
	
	svg {
		width: 100%;
		height: 100%;
		display: block;
	}
	
	&List {
		display: flex;
	}
}

.errorLabel {
	position: absolute;
	left: 0;
	top: 100%;
	padding-top: 5px;
	display: block;
	font-weight: bold;
	font-size: 12px;
	line-height: 140%;
	color: var(--status-red);
}
