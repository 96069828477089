.title {
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	color: var(--content-primary);
	cursor: pointer;
	z-index: 1;
	user-select: none;
	margin-bottom: 8px;

	span {
		color: var(--status-red);
		margin-right: 8px;
	}
}

.list {
	display: block;
}

.item {
	padding: 12px;
	border-radius: 8px;
	background-color: var(--background-secondary);
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;

	&Checkbox {
		margin-right: 16px;
	}

	&Content {
		display: block;
		width: 100%;
	}

	&Title {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		line-height: 180%;
		color: var(--content-primary);
	}

	&Description {
		font-size: 14px;
		line-height: 150%;
		color: var(--content-primary);
		margin-top: 5px;
	}

	&:not(:last-child) {
		margin-bottom: 12px;
	}
}