.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 12px;
	padding: 20px 0;

	@media screen and (max-width: 768px) {
		padding: 20px 16px;
	}

	&List {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(2, minmax(0, 1fr));

		@media screen and (max-width: 768px) {
			padding: 20px 16px;

			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}
}