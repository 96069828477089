.wrapper {
	background: linear-gradient(0deg, #007D92 1.41%, #00103A 100.37%);
	// background: url("../../assets/images/texture.jpg");
	// background-position: center;
	// background-repeat: no-repeat;
	// background-size: cover;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 1000;
	opacity: 1;
	transition: all .5s ease-in-out;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.logo {
	width: 150px;
	height: 210px;
	margin-bottom: 80px;
	color: var(--content-primary-alt);

	svg {
		width: 100%;
		height: 100%;
	}
}

.loading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&Spinner {
		width: 32px;
		height: 32px;
		margin-bottom: 24px;
	}

	&Text {
		color: var(--content-primary-alt);
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 140%;
	}
}