.list {
	position: relative;
	padding: 0 16px;
}

.item {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: var(--content-primary);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 16px 8px;
	border-top: 1px solid var(--background-line);
	cursor: pointer;
	user-select: none;
	text-align: right;
	
	&Title {
		display: block;
		margin-right: 12px;
	}
	
	&Icon {
		display: block;
		width: 20px;
		min-width: 20px;
		height: 20px;
		
		svg {
			width: 100%;
			height: 100%;
			display: block;
		}
	}
	
	+ .item {
		border-bottom: 0;
	}
}