.wrapper {
	margin-bottom: 24px;
	cursor: pointer;
	width: 100%;
	
	&Disabled {
		cursor: not-allowed;
		
		.label {
			color: var(--content-thirdary);
			
			span {
				color: var(--content-thirdary);
			}
		}
		
		.input {
			&Wrapper {
				cursor: not-allowed;
				
				> * {
					pointer-events: none;
					cursor: not-allowed;
				}
			}
		}
	}
}

.inner {
	position: relative;
	width: 100%;
}

.label {
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	color: var(--content-primary);
	cursor: pointer;
	z-index: 1;
	user-select: none;
	margin-bottom: 8px;
	
	span {
		color: var(--status-red);
		margin-right: 8px;
	}
}

.errorLabel {
	position: absolute;
	left: 0;
	top: 100%;
	padding-top: 5px;
	display: block;
	font-weight: bold;
	font-size: 12px;
	line-height: 140%;
	color: var(--status-red);
}

.input {
	&Wrapper {
		position: relative;
		z-index: 5;
	}
}

.loading {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 12px;
	margin: auto;
	width: 28px;
	height: 28px;
	background: #fff;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	padding: 4px;
	color: var(--content-primary);
	display: flex;
	align-items: center;
	justify-content: center;
}

.valueContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	
	&Open {
		.valueContainerInput {
			position: relative;
			opacity: 1;
		}
		.valueContainerPlaceholder {
			position: absolute;
			opacity: 0;
		}
	}
	
	&Input {
		display: flex;
		align-items: center;
		position: absolute;
		opacity: 0;
		overflow: hidden;
		width: 50%;
	}
	
	&Placeholder {
		display: flex;
		align-items: center;
		font-weight: 500;
		line-height: 140%;
		color: var(--field-placeholder);
		overflow: hidden;
		width: 50%;
	}
	
	&Selected {
		display: block;
		margin-right: 32px;
	}
}

.option {
	cursor: pointer;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: var(---content-primary);
	outline: none;
	width: 100%;
	transition: all .2s ease-in-out;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	padding: 16px;
	display: flex;
	align-items: center;
	
	&:not(:last-child){
		border-bottom: 1px solid var(--background-line);
	}
	
	&Checkbox,
	&Title {
		display: block;
	}
	
	&Checkbox {
		margin-right: 12px;
	}
}
