.wrapper {
	padding: 28px 24px 24px;
}

.title {
	font-weight: 600;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	color: #fff;
	margin-bottom: 28px;
}

.content {
	padding: 20px;
	background-color: var(--background-primary);
	box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
	border-radius: 16px;
	
	&Title {
		font-weight: bold;
		font-size: 20px;
		line-height: 140%;
		color: var(--brand-secondary);
		margin-bottom: 20px;
	}
}

.step {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: var(--content-primary);
	margin-bottom: 20px;
}

.info {
	font-weight: 400;
	font-size: 14px;
	line-height: 180%;
	color: var(--content-primary);
	background: var(--status-orange-light);
	border-radius: 8px;
	padding: 16px;
	margin-bottom: 20px;
}

.detail {
	&List {
		background: var(--background-secondary);
		border-radius: 8px;
		padding: 0 8px;
		margin-bottom: 20px;
	}
	
	&Item {
		padding: 16px 8px 16px 16px;
		width: 100%;
		cursor: pointer;
		user-select: none;
		position: relative;
		display: flex;
		align-items: center;
		min-height: 56px;
		
		@media (max-width: 576px) {
			padding: 12px 8px;
			flex-direction: column;
			align-items: flex-start;
		}
		
		&Title {
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: var(--content-secondary);
			flex: auto;
			padding-right: 10px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 45%;
			overflow-wrap: break-word;
			word-wrap: break-word;
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			-moz-hyphens: auto;
			hyphens: auto;
			
			@media (max-width: 576px) {
				color: var(--content-thirdary);
				margin-bottom: 6px;
				width: 100%;
			}
		}
		
		&Value {
			font-weight: bold;
			font-size: 14px;
			line-height: 17px;
			color: var(--content-primary);
			flex: auto;
			text-align: right;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 55%;
			
			&Copy {
				background: var(--background-primary);
				width: 32px;
				min-width: 32px;
				height: 32px;
				box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
				border-radius: 8px;
				margin-left: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				user-select: none;
				
				svg {
					width: 20px;
					height: 20px;
					display: block;
				}
			}
			
			@media (max-width: 576px) {
				width: 100%;
				justify-content: flex-start;
				text-align: left;
			}
		}
		
		+ .detailItem {
			border-top: 1px solid var(--background-line);
		}
	}
}

.buttonList {
	margin-bottom: 24px;
}

.receipt {
	width: 100%;
}

@media (max-width: 576px) {
	padding: 24px 0 0;
	
	.content {
		padding: 16px;
	}
	
	.detailItem {
		&Value {
			padding-right: 32px;
			
			&Copy {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
			}
		}
	}
	
	&Close {
		margin-top: 12px;
		width: 100%;
	}
}