.wrapper {
	position: fixed;
	top: 0;
	right: 0;
	padding: 25px;
	width: 100%;
	max-width: 345px;
	height: 100%;
	pointer-events: none;
	z-index: 100;
}

.item {
	background: rgba(0, 0, 0, 0.9);
	backdrop-filter: blur(25px);
	border-radius: 16px;
	padding: 12px;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
	margin-bottom: 12px;
	pointer-events: auto;
	transition: all .3s ease-in-out;
	transform: translateY(-25px);
	opacity: 0;
	visibility: hidden;
	
	&Content {
		width: 100%;
		margin-right: 6px;
	}
	
	&Title {
		font-size: 14px;
		line-height: 150%;
		display: flex;
		align-items: center;
		color: var(--content-primary-alt);
		margin-bottom: 6px;
		
		b {
			font-weight: bold;
		}
	}
	
	&Image {
		width: 40px;
		min-width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: var(--background-secondary);
		display: flex;
		align-items: center;
		justify-content: center;
		
		img {
			width: 24px;
			height: 24px;
			object-fit: contain;
			display: block;
			
			&:not([src]) {
				visibility: hidden;
			}
		}
	}
	
	&Footer {
		display: flex;
	}
	
	&Link {
		font-weight: 600;
		font-size: 10px;
		line-height: 150%;
		color: var(--brand-secondary);
		margin-right: 12px;
	}
	
	&Time {
		font-weight: 600;
		font-size: 10px;
		line-height: 150%;
		text-transform: uppercase;
		color: var(--content-thirdary);
	}
	
	+ .date {
		margin-top: 24px;
	}
}