.wrapper {
  padding: 16px;
  border-radius: 16px;
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--blue {
    background-color: var(--status-info);

    .value {
      color: var(--brand-secondary);
    }
  }
  
  &--cyan {
    background-color: var(--status-cyan-light);
    
    .value {
      color: var(--status-cyan);
    }
  }

  &--green {
    background-color: var(--status-green-light);

    .value {
      color: var(--status-green);
    }
  }

  &--red {
    background-color: var(--status-red-light);

    .value {
      color: var(--status-red);
    }
  }

  &--orange {
    background-color: var(--status-orange-light);

    .value {
      color: var(--status-orange);
    }
  }

  &--purple {
    background-color: var(--status-purple-light);

    .value {
      color: var(--status-purple);
    }
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--content-primary);
}

.value {
  font-weight: 900;
  font-size: 36px;
  color: var(--brand-secondary);
}
