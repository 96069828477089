.wrapper {
  padding: 56px 16px 16px;

  @media (max-width: 576px) {
    padding: 12px 0 0;
  }
}

.spinner {
  margin: 100px 0;
}

.header {
  display: flex;
  align-items: center;

  &Image {
    width: 256px;
    min-width: 256px;
    height: 256px;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
  }

  &Content {
    width: 100%;
    margin-left: 40px;
  }

  @media (max-width: 576px) {
    position: relative;
    z-index: -1;
    overflow: hidden;

    &Image {
      position: absolute;
      top: -20px;
      bottom: -20px;
      right: -80px;
      margin: auto;
      opacity: 0.1;
      pointer-events: none;
    }

    &Content {
      margin: 0 0 24px;
      padding: 32px 16px 0;
      display: flex;
      flex-direction: column;
      z-index: 0;
    }
  }
}

.modal {
  margin: 50px;
  padding: 20px;
  background: white;
  border-radius: 10px;
}

.appeal {
  margin: 50px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;

  &Buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
  }
}

.buttonModal {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: end;
}

.tab {
  &Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    &Inner {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--content-thirdary-alt);
      border-radius: 40px;
      padding: 2px;
    }
  }

  &Item {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 40px;
    padding: 10px 24px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    width: calc(100% + 20px);
    color: var(--content-primary-alt);

    &Active {
      background: var(--background-primary);
      box-shadow: 0 2px 15px rgba(9, 17, 38, 0.1);
      color: var(--content-primary);
    }
  }
}

.category {
  font-size: 14px;
  line-height: 17px;
  color: var(--content-secondary-alt);
  margin-bottom: 14px;

  @media (max-width: 576px) {
    order: 2;
  }
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: var(--content-primary-alt);
  margin-bottom: 14px;

  @media (max-width: 576px) {
    order: 1;
  }
}

.tags {
  margin-bottom: 14px;

  @media (max-width: 576px) {
    order: 4;

    > * {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.info {
  display: flex;

  @media (max-width: 576px) {
    order: 5;
  }
}

.content {
  background: var(--background-primary);
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 32px;

  @media (max-width: 576px) {
    padding: 16px 0;
  }
}

.image {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #ededed;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.button {
  &List {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  &Block {
    display: block;
  }
}

.listTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: var(--content-primary);
  margin-bottom: 20px;

  @media (max-width: 576px) {
    margin: 0 16px;
  }
}

.buttonList {
  display: flex;
  gap: 1rem;
  justify-content: start;
  margin-bottom: 32px;
}

.group {
  border: 1px solid var(--background-line);
  border-radius: 8px;
  padding: 16px;

  &Wrapper {
    padding: 8px 0;
  }

  &Title {
    font-weight: 800;
    font-size: 20px;
    line-height: 140%;
    color: var(--brand-secondary);
    margin-bottom: 2px;
  }

  &Fields {
    font-size: 13px;
    line-height: 140%;
    color: var(--content-primary);
    margin-bottom: 24px;
  }

  &List {
    &Item {
      background-color: var(--background-primary);

      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
}

.field {
  &File,
  &Map {
    display: flex;
    align-items: center;

    &Title {
      display: block;
      color: var(--content-primary);
    }

    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      background: var(--background-secondary);
      border-radius: 6px;
      padding: 4px;
      color: var(--content-primary);
      margin-left: 10px;

      svg {
        width: 20px;
        height: 20px;
        display: block;
      }
    }
  }
}

.detail {
  &Item {
    &Content {
      width: 100%;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      p {
        margin: 0;

        + p {
          margin-top: 24px;
        }
      }
    }

    &Html {
      flex-direction: column;

      > div {
        width: 100%;

        &:first-child {
          margin-bottom: 24px;
        }
      }
    }
  }
}

.notification {
  display: flex;
  flex-direction: column;
  padding: 2em;

  &Content {
    display: block;
    margin-top: 2em;
    text-align: justify;
    line-height: 150%;
  }

  &Footer {
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: flex-end;
    margin-top: 1em;
  }
}
